@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,400;0,700;1,400&family=Rubik:wght@700&display=swap');

/*****TYPOGRAPHY*****/

body {
  font-size: 18px !important;
  font-family: 'Karla', sans-serif !important;
  font-weight: 400 !important;
  color: #0d0e0f !important ;
  line-height: 1.5 !important;
}

.buddy-h1 {
  font-family: 'Rubik', 'sans-serif' !important;
  text-transform: uppercase !important;
  font-size: 2.5em !important;
  line-height: 1.5;
  margin-bottom: 0.5em !important;
  color: #dc5648 !important
}

@media (min-width: 768px) {

.buddy-h1 {
    font-size: 2.72em !important;

    line-height: 1.125 !important
}
  }

@media (min-width: 992px) {

.buddy-h1 {
    font-size: 3em;

    line-height: 1.05
}
  }

.buddy-h2 {
  font-family: 'Karla', sans-serif;
  font-size: 2em;
  line-height: 1.5;
  margin-bottom: 0.25em;
  color: #05445b;
  font-weight: 700
}

@media (min-width: 768px) {

.buddy-h2 {
    font-size: 2.4em
}
  }

@media (min-width: 992px) {

.buddy-h2 {
    font-size: 2.5em
}
  }

.buddy-h3 {
  font-family: 'Karla', sans-serif !important;
  font-size: 1.4em !important;
  line-height: 1.13636364 !important;
  margin-bottom: 0.25em;
  color: #0d0e0f;
  font-weight: 700 !important
}

@media (min-width: 768px) {

.buddy-h3 {
    font-size: 1.5em !important;

    line-height: 1.25 !important
}
  }

@media (min-width: 992px) {

.buddy-h3 {
    font-size: 1.5em !important;

    line-height: 1.25 !important
}
  }

.buddy-h4 {
  font-family: 'Karla', sans-serif !important;
  font-size: 1.09em !important;
  line-height: 1.11111111 !important;
  margin-bottom: 0.25em;
  color: #dc5648 !important;
  text-transform: uppercase;
  font-weight: 700 !important
}

@media (min-width: 768px) {

.buddy-h4 {
    line-height: 1.22222222 !important
}
  }

.buddy-h5 {
  font-family: 'Karla', sans-serif !important;
  font-size: 1em !important;
  line-height: 1.111111111 !important;
  margin-bottom: 0.25em;
  color: #dc5648 !important;
  text-transform: uppercase;
  font-weight: 700
}

@media (min-width: 768px) {

.buddy-h5 {
    line-height: 1.22222222 !important
}
  }

.color-primary {
  color: #dc5648 !important;
}

.color-black {
  color: #0d0e0f !important;
}

.color-blue {
  color: #33b7bf !important;
}

.color-white {
  color: #fff !important;
}

.font-weight-bold,
strong,
.color-red {
  font-weight: 700 !important;
}

.fast-spin {
  animation: spin 1s infinite linear;
}

@keyframes fast-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.bg-pale-yellow {
  --bg-opacity: 1;
  background-color: #fffaf0 !important;
  background-color: rgba(255, 250, 240, var(--bg-opacity)) !important;
}

.rainbow-input-lookalike {
  font: inherit;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(164, 167, 181, 1);
  border-radius: 12rem;
  width: 100%;
  transition: all 0.1s linear, padding 0s, border 0s;
  display: inline-block;
  padding: 0 1rem;
  line-height: 2.5rem;
  height: 2.5rem;
  color: rgba(42, 48, 57, 1);
  font-size: 1rem;
  box-sizing: border-box;
  margin: 0;
}

.rainbow-input-lookalike:focus,
.rainbow-input-lookalike:active {
  outline: 0;
  padding: 0 0.9375rem;
  border: 2px solid;
  box-shadow: 0 0 2px;
}

.rainbow-label-lookalike {
  color: rgba(63, 73, 84, 1);
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 0.125rem;
  align-self: flex-start;
  box-sizing: border-box;
  margin-left: 1rem;
}

.rainbow-asterisk {
  color: rgba(254, 72, 73, 1);
  margin: 0 0.125rem;
  box-sizing: border-box;
  border: 0;
  text-decoration: none;
  cursor: help;
}
